/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Container, Email, PageOffset} from 'components';
import {PageLayout as MDXLayout} from "components";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(Container, null, React.createElement(_components.p, null, "We do not explicitly collect any sort of personal data on our website. The contact form contains a field to let us know how to contact the sender back. However, this field is not obligatory and it is totally up to the author what kind of information to provide there."), React.createElement(_components.p, null, "Any kind of personal data the author has voluntarily submitted while filling up the body of the message, as well as in the contact field, we do not store and do not process. After we receive and read the message, it gets immediately wiped out of the system."), React.createElement(_components.p, null, "However, if you are still looking for more information then you can contact us by sending an email to ", React.createElement(Email))), "\n", React.createElement(PageOffset));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
